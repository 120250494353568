import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import edit from "../../assets/edit.png";
import UpdateProject from "./form/UpdateProject";
import "../../allCss/admincss/projectList.css";
// import { ToastContainer, toast } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { ChangeProjectStatus } from "./form/ChangeProjectStatus";
import { AddMember } from "./form/AddMember";
import { UpdateTeamMember } from "./form/UpdateTeamMember";
import _ from 'lodash';
import AuthContext from "../../context/AuthContext";

const ProjectList = () => {
  const [projectLIst, setProjectList] = useState([]);
  const [index, setIndex] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [showEditMember, setShowEditMember] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const { errorHandleLogout } = useContext(AuthContext);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  let loadingToast;
  useEffect(() => {
    let loadingToast;
    try {
      loadingToast = toast.loading("Project List is loading", {
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      });
      const getproject = async () => {
        await axios
          .get(`${BACKEND_URL}/projects/get_all_project`, { headers: headers })
          .then((res) => {
            console.log("response", res.data.projects);


            if (res.data.success) {
              const sortedData = _.orderBy(res.data.projects, [(item) => new Date(item.createdAt)], ['desc']);
              setProjectList(sortedData);
              toast.success("Project List Loaded!", {
                position: 'top-right',
                style: {
                  marginTop: '62px',
                }
              });
            }
          })
          .catch((err) => {
            // Use parentheses instead of curly braces
            // console.error("holiday error",err.response.data);
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            });
          });
      };
      getproject();
    } catch (err) {
      alert("Something went wrong, Try logging in again");

      errorHandleLogout();
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  }, [refresh]);
  const [tmIndex, setTmIndex] = useState(0);
  const handelTeamEdit = (index, tmIndex) => {
    setShowEditMember(!showEditMember)
    setIndex(index);
    setTmIndex(tmIndex);
  }

  const handelEdit = (index) => {
    setShowComponent(!showComponent);
    setIndex(index);
    handleDropdownClick(index);
  };

  const handelChangeStatus = (index) => {
    setShowChangeStatus(!showChangeStatus);
    setIndex(index);
    handleDropdownClick(index);
  };
  const handelAddMember = (index) => {
    setAddMember(!addMember);
    setIndex(index);
    handleDropdownClick(index);
  };
  const [indexValue, setIndexValue] = useState(0);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const handleDropdownClick = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const filterData = (data) => {
    const searchFields = ['code', 'name', 'status'];
    return searchFields.some(field =>
      String(data.user?.[field] || data[field]).toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  const filteredDatas = projectLIst.filter(filterData);
  const handleDownloadLogFile = async (value) => {
    handleDropdownClick(index);
    const confirmDelete = window.confirm("Are you sure you want to download this file?");
    if (confirmDelete) {
      try {
        loadingToast = toast.loading("Downloading log file started ....", {
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        });
        await axios.get(`${BACKEND_URL}/projects/log?projectId=${projectLIst[value]._id}`, { headers: headers })
          .then(res => {
            console.log('file downloaded', res);

            // Convert received data to text file format
            const logData = res.data;
            const blob = new Blob([logData], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'log.txt'; // Set the filename here
            document.body.appendChild(a);

            // Simulate a click on the anchor element to trigger the download
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            a.remove();

            toast.success("Log file downloaded successfully!", {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            });

            setRefresh(refresh + 1);
          }).catch(err => {
            console.log(err)
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            });
          });
      } catch {
        alert("Something went wrong, Try logging in again");
        // errorHandleLogout();
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }
  }

  const handelRemove = async (value) => {
    handleDropdownClick(index);
    const confirmDelete = window.confirm("Are you sure you want to delete the project?");
    if (confirmDelete) {
      try {
        loadingToast = toast.loading("Removing the project...", {
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        });
        await axios.delete(`${BACKEND_URL}/projects/delete_project?projectId=${projectLIst[value]._id}`, { headers: headers })
          .then(res => {
            toast.success(res.data.message, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            })

            setRefresh(refresh + 1);


          }).catch(err => {
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              }
            })

          })
      } catch {
        alert("Something went wrong, Try logging in again");

        // errorHandleLogout();

      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }
  }
  return (
    <div className="project_list">

      <h1>Projects:</h1>
      <br />
      <label htmlFor="searchBar">Search Project Name or ID: </label>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
      />
      <br /><br />
      <table>
        <thead>
          <th>Sl.No</th>
          <th>Project Code</th>
          <th>Project Name</th>
          <th>Project Deadline</th>
          <th>Project Status</th>
          <th>Description</th>
          <th>Team Members</th>
          <th>More</th>
        </thead>
        <tbody>
          {projectLIst ? (
            filteredDatas.map((project, index) => (
              <>
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{project.code}</td>
                  <td>{project.name}</td>
                  <td>
                    {(() => {
                      const originalDate = new Date(project.deadline);
                      const day = originalDate.getUTCDate();
                      const month = originalDate.getUTCMonth() + 1; // Months are zero-based
                      const year = originalDate.getUTCFullYear();

                      return `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""
                        }${month}-${year}`;
                    })()}
                  </td>
                  <td> {project.status}</td>
                  <td>
                    <ul>
                      {project.description
                        ? Object.entries(project.description).map(
                          ([key, value]) => (
                            <li key={key}>
                              <strong>{key}:</strong> {String(value)}
                            </li>
                          )
                        )
                        : "N/A"}
                    </ul>
                  </td>

                  <td>
                    <div
                      style={{
                        overflowY: "auto",
                        maxHeight: "84px",
                        padding: "4px 0px 0px 4px",
                        margin: "0",
                      }}
                    >
                      {project.teamMembers.map((teamMember, tmIndex) => (
                        <>
                          <ul key={tmIndex} >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "50px" }}>
                              <div>
                                <li>
                                  <strong>Role : </strong>
                                  {teamMember.role}
                                </li>

                                <li>
                                  <strong>Name : </strong>
                                  {teamMember.userId?.name}
                                </li>
                                <li>
                                  <strong>EmpID : </strong>
                                  {teamMember.userId?.empId}
                                </li>
                              </div>
                              <img
                                src={edit}
                                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                                onClick={() => handelTeamEdit(index, tmIndex)}
                              />
                            </div>
                            <hr />
                          </ul>
                        </>
                      ))}
                    </div>
                  </td>

                  <td style={{ position: "relative" }}>
                    {openDropdownIndex !== index ? (
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-filled/20/000000/forward--v1.png"
                        alt="forward--v1"
                        className="arrow"
                        onClick={() => handleDropdownClick(index)}
                      />
                    ) : (
                      <div className="smallDropdown">
                        <img
                          width="20"
                          height="20"
                          src="https://img.icons8.com/color/48/cancel--v1.png"
                          alt="forward--v1"
                          className="dropbtn"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDropdownClick(index)}
                        />
                        <div className="dropdownProject">
                          <p onClick={() => handelEdit(index)}>
                            Update Details
                          </p>
                          <p onClick={() => handelChangeStatus(index)}>
                            Change Status
                          </p>
                          <p
                            onClick={() => {
                              handelAddMember(index);
                            }}
                          >
                            Add Team Member
                          </p>
                          <p onClick={() => handleDownloadLogFile(index)}>Download Log File</p>
                          <p id="deleteProject" onClick={() => handelRemove(index)} >Delete Project</p>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </>
            ))
          ) : (
            <h1>Unable to load data</h1>
          )}
          { }
        </tbody>
      </table>
      {showComponent && (
        <UpdateProject
          index={index}
          projectLIst={projectLIst}
          setShowComponent={setShowComponent}
          setRefresh={setRefresh}
          refresh={refresh}

        />
      )}

      {showChangeStatus && (
        <ChangeProjectStatus
          index={index}
          projectLIst={projectLIst}
          setShowChangeStatus={setShowChangeStatus}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {addMember && (
        <AddMember
          index={index}
          projectLIst={projectLIst}
          setAddMember={setAddMember}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {
        showEditMember && (
          <UpdateTeamMember
            index={index}
            tmIndex={tmIndex}
            projectLIst={projectLIst}
            setShowEditMember={setShowEditMember}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        )
      }
      {/* <ToastContainer /> */}
      <Toaster />
    </div>
  );
};

export default ProjectList;
