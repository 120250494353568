import React, { useContext, useEffect, useState } from 'react'
import "../../allCss/admincss/addProject.css"
import axios from 'axios'
import AuthContext from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const roles = [
    "HR Specialist", "HR Manager", "HR Business Partner", "HR Executive", "HR Generalist",
    "HR Recruiter", "Sr HR Recruiter", "Account Manager", "Business Development Manager",
    "Business Partner", "Account Executive", "Project Manager", "Developer", "Sr Developer",
    "Software Developer", "Front End Developer", "Java Developer", "Software Architect",
    "Full Stack Developer", "Web Developer", "Software Engineer", "Freelancer",
    "Internship HR", "Internship IT"
];
export const AddProject = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const { handelSignOut } = useContext(AuthContext)
    const [data, setData] = useState([]);
    const [startingDate, setStartingDate] = useState('')
    let loadingToast; // Declare the variable outside the try block
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
      }
    useEffect(() => {

        try {
            loadingToast = toast.loading("Employee List is loading");
            const fetchData = async () => {
                const res = await axios.get(`${BACKEND_URL}/users/get_all_user`,{headers: headers})
                if (res) {
                    console.log(res.data)
                    setData(res.data.users)
                    toast.success("Employee List Loaded!")
                }
                else {
                    toast.error(`${res.data.message}`)
                }
            }
            fetchData();
        } catch (err) {
            alert("session timeout", err);
            toast.error("Session Timeout")
            handelSignOut();
        } finally {
            // Close the loading state
            if (loadingToast) {
                toast.dismiss(loadingToast);
            }
        }
    }, [])

    const [formData, setFormData] = useState({
        name: '',
        code: '',
        startingDate: '',
        deadline: '',
        description: {},
        tempKey: '',
        tempValue: '',
    });


    const resetForm = () => {
        setFormData({
            name: '',
            code: '',
            startingDate: '',
            deadline: '',
            description: {},
            tempKey: '',
            tempValue: '',
        });
        setTeamMembers([])

    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleDescriptionChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            description: {
                ...prevData.description,
                [key]: value,
            },
        }));
    };

    const handleAddDescription = () => {
        if (formData.tempKey && formData.tempValue) {
            setFormData((prevData) => ({
                ...prevData,
                description: {
                    ...prevData.description,
                    [formData.tempKey]: formData.tempValue,
                },
                tempKey: '',
                tempValue: '',
            }));
        }
    };
    const [teamMembers, setTeamMembers] = useState([]);
    const [finalTeamMember, setFinalTeamMember] = useState([])
    const [selectedRole, setSelectedRole] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const filteredRoles = roles.filter(role =>
        role.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleAddUser = (userId, name) => {
        const selectedUser = data.find((user) => user._id === userId);

        if (!selectedRole) {
            if (!startingDate) {
                alert("Please fill up the starting date");
                return;
            }
            alert('Please select a role before adding a user.');
            return;
        }

        // Prompt the user for additional information
        const moreInfo = promptForKeyValuePairs();

        // Add the user to the teamMembers array
        setTeamMembers((prevTeamMembers) => [
            ...prevTeamMembers,
            {
                userId: selectedUser._id,
                role: selectedRole,
                staringDate: startingDate,
                name: name,
                more: moreInfo,
            },
        ]);
        setFinalTeamMember((prevTeamMembers) => [
            ...prevTeamMembers,
            {
                userId: selectedUser._id,
                role: selectedRole,
                staringDate: startingDate,
                name: name,
                more: moreInfo,
            },
        ]);


        // Reset selectedRole after adding the user
        setSelectedRole('');
    };

    const promptForKeyValuePairs = () => {
        const moreInfo = {};
        let continueAdding = true;

        while (continueAdding) {
            const key = prompt('Enter a key:');
            const value = prompt('Enter a value:');

            moreInfo[key] = value;

            continueAdding = window.confirm('Do you want to add another key-value pair?');
        }

        return moreInfo;
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // if (!isFormValid) {
        //     alert('Fill up all details before submitting.');
        //     return;
        // }
        // Add logic to handle form submission
        console.log('Form submitted:', formData, teamMembers);
        const postData = {
            name: formData.name,
            code: formData.code,
            staringDate: formData.startingDate,
            deadline: formData.deadline,
            description: formData.description,
            teamMembers: teamMembers
        }
        console.log("postData", postData)
        try {
            loadingToast = toast.loading("Adding New Project..");
            const postDatas = async () => {
                const res = await axios.post(`${BACKEND_URL}/projects/create`, postData,{headers: headers});
                if (res) {
                    console.log(res.data)
                    toast.success("Employee List Loaded!")
                    resetForm();
                    setFinalTeamMember("");
                    setTeamMembers("")

                }
                else toast.error(`${res.data.message}`)
            }
            postDatas();
        } catch (err) {
            alert("session timeout", err);
            toast.error("Session Timeout")
            handelSignOut();
        } finally {
            // Close the loading state
            if (loadingToast) {
                toast.dismiss(loadingToast);
            }
        }
    };

    return (
        <div className="addProject">
            <div className="left">
                <form >
                    <label>
                        Project Name:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Project Code:
                        <input
                            type="text"
                            name="code"
                            value={formData.code}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Starting Date:
                        <input
                            type="date"
                            name="startingDate"
                            value={formData.startingDate}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Deadline:
                        <input
                            type="date"
                            name="deadline"
                            value={formData.deadline}
                            onChange={handleChange}
                        />
                    </label>

                    <div>
                        <label>
                            Description:
                        </label>

                        {Object.entries(formData.description).map(([key, value], index) => (
                            <div key={index}>
                                {key}:
                                <input
                                    type="text"
                                    value={value}
                                    onChange={(e) => handleDescriptionChange(key, e.target.value)}
                                />
                            </div>
                        ))}

                        <div>
                            <input
                                type="text"
                                placeholder="Key"
                                value={formData.tempKey}
                                onChange={(e) => setFormData((prevData) => ({ ...prevData, tempKey: e.target.value }))}
                            />
                            :
                            <input
                                type="text"
                                placeholder="Value"
                                value={formData.tempValue}
                                onChange={(e) => setFormData((prevData) => ({ ...prevData, tempValue: e.target.value }))}
                            />
                            <button type="button" onClick={handleAddDescription}>Add</button>
                        </div>
                    </div>

                    {/* Dropdown for selecting role */}
                    <div>
                        <h2>Team Members:</h2>
                        <br />
                        <label>Select Role:</label>
                        <input
                            type="text"
                            placeholder="Search roles..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onClick={(e)=>e.stopPropagation()}
                        />
                        <select
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value)}
                        >
                            <option value="">Select Role</option>
                            {filteredRoles.map(role => (
                                <option key={role} value={role}>{role}</option>
                            ))}
                        </select>
                        <br />
                        <label>
                            Starting Date:
                            <input
                                type="date"
                                name="startingDate"
                                value={startingDate}
                                onChange={(e) => setStartingDate(e.target.value)}
                            />
                        </label>
                    </div>

                    {/* Display the team members */}
                    {
                        finalTeamMember &&
                        <div>

                            <ul>
                                {finalTeamMember.map((member, index) => (
                                    <li key={index}>
                                        Name:{member.name}, Role: {member.role},Starting Date:{member.staringDate} More Info: {JSON.stringify(member.more)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }



                    <button style={{ cursor: 'pointer' }} onClick={handleSubmit} >Submit</button>
                </form>
            </div>
            <div className="right">
                <table>
                    <thead>
                        <th>Sl no</th>
                        <th>Emp Code</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Add</th>
                    </thead>
                    <tbody>
                        {
                            data.map((item, index) => (
                                <tr key={item._id}>
                                    <td>{index + 1}</td>
                                    <td>{item.empId}</td>
                                    <td>{item.name}</td>

                                    <td>{item.designation}</td>
                                    <td style={{ cursor: 'pointer' }}>
                                        <button className="addUser" onClick={() => handleAddUser(item._id, item.name)}> ➕</button>

                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>
            <ToastContainer autoClose={2500} />
        </div>
    )
}
