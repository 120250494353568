import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Holiday from "./Holiday";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import "../../allCss/usercss/Timesheet.css";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from "react-hot-toast";
import { leftArrow, left_arrow, rightArrow } from "../../assets";

function Timesheet() {
  const { handelSignOut, errorHandleLogout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);
  const [searchProject, setSearchProject] = useState([]); // for search project
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [weelyTotals, SetWeeklyTotals] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [isOk, setIsOk] = useState(false);

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const role = localStorage.getItem("role");
  useEffect(() => {
    if (role === "admin") setAdmin(true);
  }, []);
  let userId;
  if (admin) userId = localStorage.getItem("adminSetUser");
  else userId = localStorage.getItem("userId");
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const presentdate = new Date();
  const datevalue = presentdate.toISOString().split("T")[0];
  const [formValue, setFormValue] = useState({
    dte: datevalue,
    project_name: "",
    project_id: "",
    search_name: "",
    workInProgress: "",
    workCompleted: "",
    HoursWorked: "",
    work: [],
    timeSheetId: "",
    dateData: "",
    leave: "",
    isHalfDay: "",
  });
  // console.log("formValue", formValue.dte);
  // console.log("parseDate", parseInt(formValue.dte.split("-")[2]));
  // const monthPart = parseInt(formValue.dte.split("-")[1], 10); // Parse as base 10
  // const actualMonth = monthPart + 1;

  // console.log("parseMonth", monthPart);
  const [validationMessages, setValidationMessage] = useState({
    project_name: "",
    workInProgress: "",
    workCompleted: "",
    HoursWorked: "",
  });

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  // console.log("year", year, "     ", "month", month)
  const [currentDate, setCurrentDAte] = useState(new Date());
  const [submittedData, setSubmittedData] = useState({
    [`${year}-${month}`]: [],
  });
  const [showForm, setShowFrom] = useState(false);
  const [validationSubmit, setValidationSubmit] = useState();
  //for project name search code
  const [options, setOptions] = useState([]);
  useEffect(() => {
    try {
      const fetchData = async () => {
        await axios
          .get(`${BACKEND_URL}/users/profile?userId=${userId}`, { headers: headers })
          .then((res) => {
            console.log("response of profile", res.data.user.projects);
            if (res.data.user.projects) {
              // Filter out objects where projectId is not null
              const filteredProjects = res.data.user.projects.filter(
                (project) => project.projectId !== null
              );
              // Create an array of projectIds
              const projectIds = filteredProjects.map((project) => project.projectId);
              setOptions(projectIds);
            }
          })
          .catch((err) => {
            console.log("err", err);
            // toast.error("Unable to fetch Projects", {
            //     position: "top-right",
            //     style: {
            //         marginTop: "62px",
            //     },
            // });
          });
      };
      fetchData();
    } catch (err) {
      console.log("err", err);
      alert("Something went wrong, Try logging in again");

      errorHandleLogout();
    }
  }, []);
  //   useEffect(()=>{
  // if(options) console.log("options of project",options)
  //   },[options])
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(options);
  const [work, setWork] = useState([]);
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredResults = options.filter(
      (item) =>
        (item.name && item.name.toLowerCase().includes(query)) ||
        (item.code && item.code.toLowerCase().includes(query))
    );

    setFilteredData(filteredResults);
  };
  const handleProjectClick = (project) => {
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      project_name: project.name,
      project_id: project._id,
      // Update other fields based on your requirement
    }));
  };

  const handleAddWork = () => {
    // Check if project_name is not empty
    if (formValue.project_name) {
      setIsOk(true);
      const newWork = {
        project: formValue.project_id,
        completedTask: formValue.workCompleted,
        inProgressTask: formValue.workInProgress,
      };

      setWork((prevWork) => [...prevWork, newWork]);

      // Clear the work-related fields after adding work
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        project_name: "",
        workInProgress: "",
        workCompleted: "",
        work: [],
      }));
    }
  };
  const handleDeleteLeave = async (value) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this approved leave?"
    );
    if (confirmDelete) {
      try {
        await axios
          .delete(
            `${BACKEND_URL}/users/leave/change_leave_to_work?timesheetId=${value}`,
            { headers: headers }
          )
          .then((res) => {
            console.log(res);
            setRefresh(refresh + 1);
            toast.success(res.data.message, {
              position: "top-right",
              style: {
                marginTop: "62px",
              },
            });
            window.location.reload();
          });

        // Optionally, you can perform additional actions after successful deletion
      } catch (error) {
        console.error(error);
        // Handle error if deletion fails
      }
    }
  };

  // useEffect(() => {
  //   if (work) {
  //     console.log("work", work)
  //   }
  // }, [work])
  const changeMonth = (amount) => {
    const newDate = new Date(year, month - 1 + amount, 1);
    setYear(newDate.getFullYear());
    setMonth(newDate.getMonth() + 1);
  };
  const Calendar = (year, month) => {
    // console.log("Calendar year", year, "Calendar month", month)
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);
    const daysInMonth = lastDay.getDate();
    const startingDay = firstDay.getDay();
    // console.log("daysInMonth", daysInMonth, "startingDay", startingDay);
    const calendarData = [];

    // Fill in the dates for the previous month
    const prevMonth = month === 1 ? 12 : month - 1;
    const prevYear = month === 1 ? year - 1 : year;
    const lastDayPrevMonth = new Date(prevYear, prevMonth, 0).getDate();
    // console.log("prevMonth", prevMonth)
    // console.log("prevYear", prevYear)
    // console.log("lastDayPrevMonth", lastDayPrevMonth)
    for (let i = startingDay - 1; i >= 0; i--) {
      const date = lastDayPrevMonth - i;
      const dateString = `${prevYear}-${prevMonth.toString().padStart(2, "0")}-${date
        .toString()
        .padStart(2, "0")}`;
      calendarData.push({
        date,
        month: prevMonth,
        year: prevYear,
        project_name: null,
        workInProgress: null,
        workCompleted: null,
        hoursWorked: null,
        work: [],
        timeSheetId: null,
        dateData: dateString,
        leave: null,
        isHalfDay: null,
      });
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      calendarData.push({
        date: day,
        month: month,
        year: year,
        project_name: null,
        workInProgress: null,
        workCompleted: null,
        hoursWorked: null,
        work: [],
        timeSheetId: null,
        dateData: dateString,
        leave: null,
        isHalfDay: null,
      });
    }
    return calendarData;
  };

  const [calenderData, setCalenderData] = useState(Calendar(year, month));

  const formatStartingDate = (year, month, day) => {
    // Ensure the month and day are formatted as two digits
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  useEffect(() => {
    setStartingDate(
      formatStartingDate(calenderData[0].year, calenderData[0].month, calenderData[0].date)
    );
  }, [calenderData[0].year, calenderData[0].month, calenderData[0].date]);
  // console.log("startingDate", startingDate);

  const formatEndingDate = (year, month, day) => {
    // Ensure the month and day are formatted as two digits
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  useEffect(() => {
    setEndingDate(
      formatEndingDate(
        year,
        calenderData[calenderData.length - 1].month,
        calenderData[calenderData.length - 1].date
      )
    );
  }, [
    year,
    calenderData[calenderData.length - 1].month,
    calenderData[calenderData.length - 1].date,
  ]);
  // console.log("endingDate", endingDate)

  useEffect(() => {
    setCalenderData(Calendar(year, month));
  }, [year, month]);
  // console.log("calenderData: ", calenderData);

  const today = new Date();

  const isSunday = (day) => {
    const date = new Date(year, month - 1, day);
    return date.getDay() === 0;
  };
  // get timesheet for a perticular user on a specific date
  // useEffect(() => {
  //   try {
  //     const fetchData = async () => {
  //       const res = await axios.get(`${BACKEND_URL}/users/time_sheet/get_data?userId=${userId}&date=${formValue.dte}`, { headers: headers });
  //       if (res.data.success) {
  //         // console.log("fetchData", res)
  //         toast.success(`${res.data.message}`)
  //         if (res.data.data === "timesheet not found") {
  //           setFormValue({
  //             ...formValue,
  //             project_name: "",
  //             workInProgress: "",
  //             workCompleted: "",
  //             HoursWorked: "",
  //             work: [],
  //             timeSheetId: "",
  //           });
  //         } else {
  //           const { HoursWorked, project_name, workCompleted, workInProgress } =
  //             res.data.data;
  //           setFormValue({
  //             ...formValue,
  //             HoursWorked,
  //             project_name,
  //             workCompleted,
  //             workInProgress,
  //             work,
  //           });
  //         }
  //       }
  //       else {
  //         toast.error(`${res.data.message}`)
  //       }

  //     }
  //     fetchData();
  //   } catch (err) {
  //     alert("session timeout", err);
  //     toast.error("Session Timeout")
  //     handelSignOut();
  //   }

  // }, []);

  const [apiDatas, setApiDatas] = useState([]);
  //get all timesheet for a perticular user within a date range

  useEffect(() => {
    if (startingDate && endingDate) {
      try {
        const fetchData = async () => {
          const res = await axios.get(
            `${BACKEND_URL}/users/time_sheet/get_data_range?userId=${userId}&startDate=${startingDate}&endDate=${endingDate}`,
            { headers: headers }
          );
          if (res.data.success) {
            console.log("fetchData", res);
            console.log("refresh count", refresh);
            setApiDatas(res.data.data);
            // toast.success(`${res.data.message}`, {
            //     position: "top-right",
            //     style: {
            //         marginTop: "62px",
            //     },
            // });
          } else {
            // toast.error(`${res.data.message}`, {
            //     position: "top-right",
            //     style: {
            //         marginTop: "62px",
            //     },
            // });
          }
        };
        if (userId) {
          fetchData();
        }
      } catch (err) {
        alert("session timeout", err);
        toast.error("Session Timeout", {
          position: "top-right",
          style: {
            marginTop: "62px",
          },
        });
        handelSignOut();
      }
    }
  }, [startingDate, endingDate, refresh, userId]);
  // useEffect(() => {

  //     .then((res) => {
  //       setApiDatas(res.data.data);
  //     })
  //     .catch((err) => console.log("all Error", err));
  // }, [startingDate, endingDate, refresh]);

  useEffect(() => {
    // Function to match and update calendarData based on API data
    function updateCalendarData(apiDatas, calenderData) {
      for (let apiEntry of apiDatas) {
        const apiDate = new Date(apiEntry.dte);
        const apiYear = apiDate.getUTCFullYear();
        const apiMonth = apiDate.getUTCMonth() + 1; // Months are zero-based
        const apiDay = apiDate.getUTCDate();

        for (let i = 0; i < calenderData.length; i++) {
          const calendarEntry = calenderData[i];
          if (
            calendarEntry.date === apiDay &&
            calendarEntry.month === apiMonth &&
            calendarEntry.year === apiYear
          ) {
            // Update calenderData with corresponding API data
            calendarEntry.hoursWorked = apiEntry.HoursWorked;
            calendarEntry.workCompleted = apiEntry.workCompleted;
            calendarEntry.work = apiEntry.work;
            calendarEntry.timeSheetId = apiEntry._id;
            calendarEntry.leave = apiEntry.leave;
            calendarEntry.isHalfDay = apiEntry.isHalfDay;
            // Add more updates as needed

            break; // Stop the loop once the match is found
          }
        }
      }
    }

    // Call the function to update calenderData
    updateCalendarData(apiDatas, calenderData);
    // Assuming you have a function to set calenderData
    setCalenderData([...calenderData]);

    console.log("api datas", apiDatas);
  }, [apiDatas, refresh, setApiDatas]);

  useEffect(() => {
    // const data= calenderData[calenderData.length - 3]?.date ;
    console.log("calenderdata", calenderData);
  }, [calenderData]);

  const [showLeft, setShowLeft] = useState(true);
  //to show the previous data
  const handleShowPrevData = (i) => {
    setShowLeft(false);
    const { year, month, date } = calenderData[i];
    const dte = new Date(year, month - 1, date);
    if (calenderData[i].hoursWorked) {
      setFormValue({
        ...formValue,

        project_name: calenderData[i].project_name,
        workInProgress: calenderData[i].workInProgress,
        workCompleted: calenderData[i].workCompleted,
        HoursWorked: calenderData[i].hoursWorked,
        work: calenderData[i].work,
        timeSheetId: calenderData[i].timeSheetId,
        dateData: calenderData[i].dateData,
        leave: calenderData[i].leave,
        isHalfDay: calenderData[i].isHalfDay,
      });
    } else {
      setFormValue({
        ...formValue,

        project_name: "",
        workInProgress: "",
        workCompleted: "",
        HoursWorked: "",
        work: calenderData[i].work,
        timeSheetId: calenderData[i].timeSheetId,
        dateData: calenderData[i].dateData,
        leave: calenderData[i].leave,
        isHalfDay: calenderData[i].isHalfDay,
      });
    }
  };
  // to check  that the user id is present or not
  useEffect(() => {
    handleUndefined();
  }, []);
  const handleUndefined = () => {
    if (!userId) {
      alert("Session Timeout");
      localStorage.removeItem("token");
      navigate("/");
    }
  };
  // api using post method to post the in the database

  const timesheetForm = async () => {
    const postData = {
      dte: formValue.dte,
      HoursWorked: formValue.HoursWorked,
      work: work,
    };
    try {
      handleUndefined();
      await axios
        .post(`${BACKEND_URL}/users/time_sheet/create?userId=${userId}`, postData, {
          headers: headers,
        })
        .then((res) => {
          console.log("success", res.data);
          // alert(res.data.message);
          toast.success(`${res.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
          setFormValue({
            dte: datevalue,
            project_name: "",
            project_id: "",
            search_name: "",
            workInProgress: "",
            workCompleted: "",
            HoursWorked: "",
          });
          setWork([]);
          setRefresh(refresh + 1);
          setIsOk(false);
        })
        .catch((err) => {
          console.log("errrrrr", err);
          toast.error(err.response.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handelSubmit = (e) => {
    e.preventDefault();

    const postData = {
      dte: formValue.dte,
      HoursWorked: formValue.HoursWorked,
      work: work,
    };

    console.log(postData);
    if (isOk && formValue.HoursWorked) {
      timesheetForm();

    } else {
      alert("Fill your hours worked");
    }

  };

  //function for holiday list
  const handelHoliday = () => {
    // console.log("data");
    setShowFrom(true);
  };

  //function for download the sheet in google sheet
  const handelDownload = () => {
    const ws = XLSX.utils.table_to_sheet(document.querySelector(".calender-table"));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `timesheet_${year}_${month}.xlsx`);
  };

  //Function to calculate weekly totals
  const calculateWeeklyTotals = () => {
    const newWeeklyTotals = [];

    for (let i = 0; i < calenderData.length; i += 7) {
      const weekTotal = calenderData
        .slice(i, i + 7)
        .reduce((total, calData) => total + (parseFloat(calData.hoursWorked) || 0), 0);
      newWeeklyTotals.push(weekTotal);
    }
    // console.log("calculate total week:",newWeeklyTotals)
    SetWeeklyTotals(newWeeklyTotals);
  };
  useEffect(() => {
    calculateWeeklyTotals();
  }, [calenderData]);

  //for projectname onclick
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setFormValue({ ...formValue, project_name: [inputValue] });
  };
  //for search option click
  const handleOptionClick = (option) => {
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      project_name: [...(prevFormValue.project_name || []), option],
    }));
    setSearchQuery("");
  };
  // const filteredOptions = options.filter((option) =>
  //   option.includes(searchQuery.toLowerCase())
  // );
  const [holidays, setHolidays] = useState([]);
  useEffect(() => {
    try {
      const fetchData = async () => {
        await axios
          .get(
            `${BACKEND_URL}/holiday/get_holidays?startDate=${startingDate}&endDate=${endingDate}`,
            { headers: headers }
          )
          .then((res) => {
            console.log("holiday leave list :", res);
            // console.log("holiday leave list :",res.data.data);
            setHolidays(res.data.data);
          })
          .catch((err) => {
            // Use parentheses instead of curly braces
            console.error("holiday error", err.response.data);
            // toast.error(`${err.response.data.message}`,{
            //   position: 'top-right',
            //   style: {
            //     marginTop: '62px',
            //   }
            // })
          });
      };
      if (startingDate) fetchData();
    } catch (err) {
      console.log("response holiday", err);
    }
  }, [startingDate, endingDate]);

  const [arrayData, setArrayData] = useState([]);
  const [allValidDates, setAllValidDates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `${BACKEND_URL}/users/leave/view_particular_leaves?status=approved&userId=${userId}`,
          { headers: headers }
        )
        .then((res) => {
          console.log("applyLeave all data", res.data.data);
          setArrayData(res.data.data);
        })
        .catch((err) => {
          console.log("Error", err);
          alert("Leave not found");
          toast.error("Leave not found", {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        });
    };
    fetchData();
  }, [userId, refresh]);

  useEffect(() => {
    if (arrayData) {
      const dates = arrayData.reduce((acc, curr) => {
        return acc.concat(curr.validDates);
      }, []);

      setAllValidDates(dates);
    }
  }, [arrayData]);
  useEffect(() => {
    // if (allValidDates) console.log("allValidDates", allValidDates);
    // if (holidays) console.log("holidays", holidays);
    if (formValue) console.log("formValue", formValue.dte);
  }, [allValidDates, holidays, formValue]);

  const [isFormDateValid, setIsFormDateValid] = useState(false);

  useEffect(() => {
    // Function to compare dates in the same format
    const compareDates = (date1, date2) => {
      const formattedDate1 = new Date(date1).toISOString().split("T")[0]; // Convert formValue.dte to ISO format
      // const formattedDate2 = new Date(date2); // Ensure both dates have the same format
      // console.log("formattedDate1",formattedDate1,"formattedDate2",date2)
      return formattedDate1 === date2;
    };

    // Check if formValue.dte matches any of the allValidDates
    const checkFormDateValidity = () => {
      const isValid =
        admin && allValidDates.some((date) => compareDates(date, formValue.dateData));
      setIsFormDateValid(isValid);
    };

    // Call the function initially and whenever formValue.dte changes
    checkFormDateValidity();
  }, [formValue.dte, admin, allValidDates]);
  return (
    <>
      <div className="main-content">
        <div className="right-content">
          <div className="calender-month">
            <img
              onClick={() => changeMonth(-1)}
              width="20"
              height="20"
              src={leftArrow}
              alt="circled-chevron-left"
            />
            <span>{`${month}/${year}`}</span>
            <img
              onClick={() => changeMonth(1)}
              width="20"
              height="20"
              src={rightArrow}
              alt="circled-chevron-right"
            />
          </div>
          <div className="cal-week">
            <table className="calender-table">
              <thead>
                <th>Su</th>
                <th>Mo</th>
                <th>Tu</th>
                <th>We</th>
                <th>Th</th>
                <th>Fr</th>
                <th>Sa</th>
              </thead>

              <tbody className="calender-body">
                {calenderData.map((calData, index) => {
                  // Check if the date exists in holidays array
                  const isHoliday = holidays.find((holiday) => {
                    const holidayDate = new Date(holiday.dte).getDate();
                    const holidayMonth = new Date(holiday.dte).getMonth() + 1;
                    return (
                      calData.date === holidayDate &&
                      calData.month === holidayMonth
                    );
                  });

                  // Get current date
                  const currentDate = new Date();
                  const currentDay = currentDate.getDate();
                  const currentMonth = currentDate.getMonth() + 1;
                  const currentYear = currentDate.getFullYear();
                  // Check if the date is before the present date
                  const isDateBeforePresent = () => {
                    return (
                      currentYear > calData.year ||
                      (currentYear === calData.year &&
                        currentMonth > calData.month) ||
                      (currentYear === calData.year &&
                        currentMonth === calData.month &&
                        currentDay > calData.date)
                    );
                  };
                  return (
                    <React.Fragment key={index}>
                      <td
                        onClick={() => {
                          {
                            console.log(index);
                            handleShowPrevData(index);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        className={`${calData.isHalfDay === true
                          ? calData.work && calData.work.length > 0
                            ? "halfdayWorked"
                            : "halfdayNotWorked"
                          : calData.date === currentDay &&
                            calData.month === currentMonth
                            ? "today"
                            : calData.leave === true
                              ? "isLeave"
                              : (index % 7 === 0 || isHoliday) &&
                                !calData.hoursWorked
                                ? "holiday"
                                : calData.work && calData.work.length > 0
                                  ? "filled"
                                  : isDateBeforePresent()
                                    ? "notFilled"
                                    : ""
                          }`}
                      >
                        {calData.date !== null ? calData.date : ""}
                        <p>{calData.hoursWorked}</p>
                      </td>
                      {(index + 1) % 7 === 0 &&
                        index + 1 !== calenderData.length && (
                          <tr key={`row-${index / 7}`}></tr>
                        )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="weekly">
              <p>
                <b>Weekly </b>
              </p>

              {weelyTotals.map((total, index) => (
                <input
                  style={{ marginBottom: "30px" }}
                  key={index}
                  type="text"
                  value={total}
                  readOnly
                />
              ))}
            </div>
          </div>

          <div className="Total">
            <p>
              Total :
              <input
                type="number"
                value={weelyTotals.reduce(
                  (total, weekTotal) => total + weekTotal,
                  0
                )}
                readOnly
              />
            </p>
          </div>

          <div className="assigned-table">
            <table>
              <tbody>
                <tr>
                  <th style={{ backgroundColor: "rgb(219 245 219)" }}>
                    filled Hours
                  </th>
                  <th style={{ backgroundColor: "rgb(237 210 215)" }}>
                    Not Filled Hours
                  </th>
                  <th style={{ backgroundColor: "rgb(241 231 202)" }}>
                    Holidays
                  </th>
                  <th style={{ backgroundColor: "rgb(183 179 229)" }}> Leave</th>
                  <th
                    style={{
                      backgroundImage:
                        "linear-gradient(79deg, rgb(219, 245, 219), rgb(183, 179, 229))",
                    }}
                  >
                    {" "}
                    halfDay Fielld
                  </th>
                  <th
                    style={{
                      backgroundImage:
                        "linear-gradient(79deg, rgb(237, 210, 215), rgb(183, 179, 229))",
                    }}
                  >
                    {" "}
                    halfDay NotFielld
                  </th>
                  {/* <th style={{backgroundImage: linear-gradient(79deg,#34cadb,#8c6fc6 55%, #4de7f7 );}}></th> */}
                </tr>
              </tbody>
            </table>
            <p onClick={() => handelHoliday()}>
              <u>Show Holidays</u>
            </p>
          </div>
          {/* <p onClick={handelDownload} style={{ cursor: "pointer" }}>
            <u>
              Download this month<sup>,</sup>s timeSheet
            </u>
          </p> */}
        </div>

        {showForm && (
          <Holiday
            setShowFrom={setShowFrom}
            startingDate={startingDate}
            endingDate={endingDate}
          />
        )}

        {showLeft ? (
          <div className="left-content">
            {admin && (
              <div>
                <h2>Do you want to go back ?</h2>
                <img
                  src={left_arrow}
                  alt="leftArrow"
                  onClick={() => {
                    navigate("/admin/home");
                    localStorage.removeItem("adminSetUser");
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
            <form className="fomr" onSubmit={handelSubmit}>
              {/* 
            USE THIS 
            new Date(calenderData[calenderData.length - 3]?.date).getDate()
            OR THIS TO CHECK DATE IS WORKING PROPERLY OR NOT
            calenderData[calenderData.length - 3]?.date */}
              <label htmlFor="dte" style={{ width: '50%' }}> Select Date:
                <select
                  name="dte"
                  value={formValue.dte}
                  onChange={(e) =>
                    setFormValue({ ...formValue, dte: e.target.value })
                  }
                >
                  {admin
                    ? // Code to be executed if the user is an admin
                    calenderData.map((item) => {
                      const isoDate = `${item.year}-${String(
                        item.month
                      ).padStart(2, "0")}-${String(item.date).padStart(
                        2,
                        "0"
                      )}`;

                      return (
                        <option key={isoDate} value={isoDate}>
                          {isoDate}
                        </option>
                      );
                    })
                    : currentDate.getDate() ===
                      calenderData[calenderData.length - 3]?.date
                      ? // Code to be executed if the condition is true
                      [...Array(5)].map((_, index) => {
                        const offset = index - 2;
                        const date = new Date();
                        date.setDate(currentDate.getDate() + offset);

                        // Format the date in ISO format (YYYY-MM-DD)
                        const isoDate = date.toISOString().split("T")[0];

                        return (
                          <option key={isoDate} value={isoDate}>
                            {isoDate}
                          </option>
                        );
                      })
                      : // Code to be executed if the condition is false
                      [0, 1, 2].map((offset) => {
                        const date = new Date();
                        date.setDate(currentDate.getDate() - offset);

                        // Format the date in ISO format (YYYY-MM-DD)
                        const isoDate = date.toISOString().split("T")[0];

                        return (
                          <option key={isoDate} value={isoDate}>
                            {isoDate}
                          </option>
                        );
                      })}
                </select>
              </label>
              {admin ? null : (
                <div className="task">
                  <div className="project-search">
                    <label htmlFor="search" id="searchLabel">
                      {" "}
                      Search Project :
                      <input
                        id="project_search"
                        type="text"
                        placeholder="Search by name or code"
                        value={searchQuery}
                        style={{ marginLeft: "20px" }}
                        onChange={handleSearch}
                      />
                      <ul className="filteredSearch" id="style-10">
                        {searchQuery !== "" && filteredData.length > 0 ?
                          filteredData.map((item) => (
                            <li
                              key={item.code}
                              onClick={() => handleProjectClick(item)}
                            >
                              <p>Name: {item.name}</p>
                              <p>Code: {item.code}</p>
                              {/* Add other fields you want to display */}
                            </li>
                          ))
                          :
                          <li style={{ padding: '5px' }}>
                            <p>No Such Projects Assigned to you</p>
                            {/* Add other fields you want to display */}
                          </li>
                        }
                      </ul>
                    </label>
                  </div>

                  <div className="project_input_filed">
                    <div className="project_lable_input">
                      <label htmlFor="project_name">
                        &nbsp;&nbsp;&nbsp;Project Name :
                      </label>
                      <div>
                        <textarea
                          name="project_name"
                          value={formValue.project_name}
                          onChange={handleInputChange}
                          rows={1}
                          cols={40}
                        />
                        <p style={{ color: "red" }}>
                          {validationMessages.project_name}
                        </p>
                      </div>
                    </div>

                    <div className="project_lable_input">
                      <label htmlFor="workCompleted">Completed task :</label>
                      <div>
                        <textarea
                          name="workCompleted"
                          value={formValue.workCompleted}
                          onChange={(e) =>
                            setFormValue({
                              ...formValue,
                              workCompleted: e.target.value,
                            })
                          }
                          rows={4}
                          cols={40}
                        />
                        <p style={{ color: "red" }}>
                          {validationMessages.workCompleted}
                        </p>
                      </div>
                    </div>

                    <div className="project_lable_input">
                      <label htmlFor="workInProgress">
                        Task in Progress :
                      </label>
                      <div>
                        <textarea
                          name="workInProgress"
                          value={formValue.workInProgress}
                          onChange={(e) =>
                            setFormValue({
                              ...formValue,
                              workInProgress: e.target.value,
                            })
                          }
                          rows={4}
                          cols={40}
                        />
                        <p style={{ color: "red" }}>
                          {validationMessages.workInProgress}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {admin ? null : (
                <button
                  className="submit-btn1"
                  type="button"
                  onClick={handleAddWork}
                >
                  Add Work
                </button>
              )}
              <div className="workhour-input">
                <label htmlFor="HoursWorked">
                  Hours worked =
                  <input
                    type="number"
                    id="hours_worked"
                    name="HoursWorked"
                    value={formValue.HoursWorked}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        HoursWorked: e.target.value,
                      })
                    }
                  />
                  <p style={{ color: "red" }}>{validationMessages.HoursWorked}</p>
                </label>
              </div>
              <div style={{ display: "flex", gap: "20px" }}>


                {admin ? (
                  <button className="submit-btn" type="Submit">
                    Submit
                  </button>
                ) : (
                  isOk &&
                  <button className="submit-btn" type="Submit">
                    Submit
                  </button>
                )}
              </div>
              {validationSubmit && (
                <p style={{ color: "green" }}>{validationSubmit}</p>
              )}
            </form>
          </div>
        ) : (
          <div className="left-content">
            <img
              src={left_arrow}
              width={100}
              height={100}
              alt="left_arrow"
              style={{ cursor: "pointer" }}
              onClick={() => setShowLeft(true)}
            />
            <p>
              Date is <strong>{formValue.dateData}</strong>
            </p>

            {formValue.leave ? (
              <>
                {admin ? (
                  <button
                    className="submit-btn2"
                    type="button"
                    onClick={() => handleDeleteLeave(formValue.timeSheetId)}
                  >
                    Delete Leave
                  </button>
                ) : (
                  <h2>You have requested a leave on this date.</h2>
                )}
              </>
            ) : (
              <>
                <p>Hours Worked: {formValue.HoursWorked}</p>
                <h2>Work Information:</h2>
                <ul >
                  {formValue.work.map((item, index) => (
                    <>
                      <li key={index} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <p>➡️ <strong>Project Name:</strong> {item.project?.name}</p>
                        <p> <strong>Completed Task:</strong> {item.completedTask}</p>
                        <p> <strong>In Progress Task:</strong> {item.inProgressTask}</p>
                        {/* Add more properties from 'work' if needed */}

                      </li>
                      <hr />
                    </>


                  ))}
                </ul>
              </>
            )}

            <div></div>
          </div>
        )}
      </div>
      {/* <ToastContainer /> */}
      <Toaster />
    </>
  );
}

export default Timesheet;
