import React, { useContext, useEffect, useState } from "react";
import "../../../allCss/admincss/changeprojectstatus.css";
import cross from "../../../assets/black cross.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AuthContext from "../../../context/AuthContext";

export const ChangeProjectStatus = ({
  index,
  projectLIst,
  setShowChangeStatus,
  setRefresh,
  refresh,
}) => {
  // console.log("setSelectOption", projectLIst[index].status);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const { errorHandleLogout } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [selectOption, setSelectOption] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const postData = {
        status: selectOption,
      };
    
        await axios
          .patch(
            `${BACKEND_URL}/projects/change_status?projectId=${projectLIst[index]._id}`,
            postData,
            { headers: headers }
          )
          .then((res) => {
            setSelectOption(selectOption);
            toast.success(res.data.message, {
              position: "top-right",
              style: {
                marginTop: "62px",
              },
            });
            setShowChangeStatus(false);
            setRefresh(refresh + 1);
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`, {
              position: "top-right",
              style: {
                marginTop: "62px",
              },
            });
          });
    
    } catch (err) {
      alert("Something went wrong, Try logging in again");
      errorHandleLogout();
    }
  };

  return (
    <div className="cover">
      <div className="change_project_status">
        <img src={cross} onClick={() => setShowChangeStatus(false)} />
        <h2>Do you Want to change the status</h2>
        <p>
          Now present status is : <strong>{projectLIst[index].status}</strong>
        </p>
        <form onSubmit={handleSubmit}>
          <select onChange={(e) => setSelectOption(e.target.value)}>
            <option value="InProgress">InProgress</option>
            <option value="OnHold">OnHold</option>
            <option value="Delayed">Delayed</option>
            <option value="Dropped">Dropped/Cancelled</option>
            <option value="Resumed">Resumed</option>
            <option value="Restart">Restart</option>
            <option value="Revise">Revise</option>
            <option value="Testing">Testing</option>
            <option value="Completed">Completed </option>
          </select>
          <button type="Submit">Submit</button>
        </form>
      </div>
      <Toaster />
    </div>
  );
};
