import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import AuthContext from "../../../context/AuthContext";

function UpdateProject({
  index,
  projectLIst,
  setShowComponent,
  setRefresh,
  refresh,
}) {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const { errorHandleLogout } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    deadline: "",
    description: {},
  });

  useEffect(() => {
    if (projectLIst[index]) {
      setFormData((prevData) => ({
        ...prevData,
        name: projectLIst[index].name,
        code: projectLIst[index].code,

        // Format the deadline using toLocaleDateString('en-GB'):
        deadline: new Date(projectLIst[index].deadline),

        description: projectLIst[index].description || {},
      }));
    }
  }, [index, projectLIst]);

  useEffect(() => {
    console.log("projectLIst", formData);
  }, [formData]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDescriptionChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: {
        ...prevData.description,
        [key]: value,
      },
    }));
  };
  const handleAddDescription = () => {
    if (formData.tempKey && formData.tempValue) {
      setFormData((prevData) => ({
        ...prevData,
        description: {
          ...prevData.description,
          [formData.tempKey]: formData.tempValue,
        },
        tempKey: "",
        tempValue: "",
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let loadingToast;
    try {
      loadingToast = toast.loading("Project List is loading");
      const res = await axios
        .patch(
          `${BACKEND_URL}/projects/update?projectId=${projectLIst[index]._id}`,
          formData,
          { headers: headers }
        )
        .then((res) => {
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
          setRefresh(refresh + 1);
          setShowComponent(false);
        })
        .catch((err) => {
            toast.error(`${err.response.data.message}`,{
                position: 'top-right',
                style: {
                  marginTop: '62px',
                }
              })
        });
    } catch (err) {
        alert("Something went wrong, Try logging in again");
        errorHandleLogout();
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <div className="cover">
      <div className="update_project">
        <form>
          <label>Project Name: </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />

          <label>
            Deadline:
            <input
              type="text"
              name="deadline"
              value={formData.deadline}
              onChange={handleChange}
            />
          </label>

          <div>
            <label>Description:</label>

            {Object.entries(formData.description).map(([key, value], index) => (
              <div id="description" key={index}>
                {key}:
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleDescriptionChange(key, e.target.value)}
                />
              </div>
            ))}

            <div>
              <input
                type="text"
                placeholder="Key"
                value={formData.tempKey}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    tempKey: e.target.value,
                  }))
                }
              />
              :
              <input
                type="text"
                placeholder="Value"
                value={formData.tempValue}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    tempValue: e.target.value,
                  }))
                }
              />
              <button type="button" onClick={handleAddDescription}>
                Add
              </button>
            </div>
          </div>
          <div className="buttonDiv">
            <button style={{ cursor: "pointer" }} onClick={handleSubmit}>
              Submit
            </button>
            <button
              style={{ cursor: "pointer" }}
              className="buttonCancel"
              onClick={() => setShowComponent(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
}

export default UpdateProject;
