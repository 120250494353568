import React from "react";
import "../../allCss/usercss/ceocontent.css";
import ceologo from "../../assets/ceologo.png";
import leadership from "../../assets/leadershipceo.png";
import responsbility from "../../assets/responsibilityceo.png";
import experctise from "../../assets/expertiesceo.png";
import dayasirphoto from "../../assets/dayasirphoto1.png";
import { useNavigate } from "react-router-dom";
const CEOContent = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (token && role != "emp") {
        navigate("/admin/home");
    }

    return (
        <>
            <div className="ceocontent-body">
                <div className="ceocontent-page">
                    <div className="left-ceocontent">
                        <div className="meetourceo">
                            <h1>
                                Meet Our CEO <br />
                                <span id="ceoname">Dayashankar Das</span>
                            </h1>
                        </div>
                        <div className="aboutceo">
                            <img src={ceologo}></img>
                            <div>
                                <p style={{ textAlign: "justify", paddingRight: "20px" }}>
                                    Honored to serve as CEO of our company, bringing a wealth of
                                    experience and passion to our team. With a focus on innovation,
                                    collaboration, and ethical leadership, we're poised for success.
                                    Together, let's propel our company to new heights, making a
                                    positive impact every step of the way. Remember, 'Embrace the
                                    journey, for within every challenge lies an opportunity to grow
                                    and thrive.'
                                </p>
                                <p style={{ textAlign: "justify", paddingRight: "20px" }}>
                                    At DCS, we're not just building a business; we're cultivating a
                                    community dedicated to excellence. Through transparency,
                                    integrity, and unwavering dedication, we forge ahead, united in
                                    our pursuit of greatness. Our journey is marked by resilience,
                                    creativity, and a relentless pursuit of improvement. Together,
                                    let's write the next chapter of our success story, leaving a
                                    lasting legacy for generations to come
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="right-ceocontent">
                        <img src={dayasirphoto}></img>
                        {/* <img src={dayasirphoto}></img> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CEOContent;
