import ReacstartingDatet, { useContext, useEffect, useState } from 'react'
import cross from "../../../assets/black cross.png"
import axios from 'axios'
import toast, { Toaster } from "react-hot-toast";
import AuthContext from '../../../context/AuthContext';


const roles = [
  "HR Specialist", "HR Manager", "HR Business Partner", "HR Executive", "HR Generalist",
  "HR Recruiter", "Sr HR Recruiter", "Account Manager", "Business Development Manager",
  "Business Partner", "Account Executive", "Project Manager", "Developer", "Sr Developer",
  "Software Developer", "Front End Developer", "Java Developer", "Software Architect",
  "Full Stack Developer", "Web Developer", "Software Engineer", "Freelancer",
  "Internship HR", "Internship IT"
];
export const AddMember = ({ index, projectLIst, setAddMember ,setRefresh,refresh}) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { handelSignOut } = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const { errorHandleLogout } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  const [data, setData] = useState([])
  let loadingToast; // Declare the variable outside the try block
  // console.log("data", projectLIst[index])
  useEffect(() => {

    try {
      loadingToast = toast.loading("Employee List is loading");
      const fetchData = async () => {
        const res = await axios.get(`${BACKEND_URL}/users/get_all_user`,{headers: headers})
        .then((res)=>{
          setData(res.data.users)
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        })
        .catch((err)=>{
          // console.log(err)
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        })
    
      }
      fetchData();
    } catch (err) {
      alert("Something went wrong, Try logging in again");
      errorHandleLogout();
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  }, [])
  const [teamMembers, setTeamMembers] = useState([]);
  const [finalTeamMember, setFinalTeamMember] = useState([])
  const [selectedRole, setSelectedRole] = useState('');
  const [startingDate, setStartingDate] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredRoles = roles.filter(role =>
    role.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleAddUser = (userId, name) => {
    const selectedUser = data.find((user) => user._id === userId);


    if (!selectedRole) {
      if (!startingDate) {
        alert("Please fill up the starting date");
        return;
      }
      alert('Please select a role before adding a user.');
      return;
    }

    // Prompt the user for additional information
    const moreInfo = promptForKeyValuePairs();

    // Add the user to the teamMembers array
    setTeamMembers((prevTeamMembers) => [
      ...prevTeamMembers,
      {
        userId: selectedUser._id,
        role: selectedRole,
        staringDate: startingDate,
        name: name,
        more: moreInfo,
      },
    ]);
    setFinalTeamMember((prevTeamMembers) => [
      ...prevTeamMembers,
      {
        userId: selectedUser._id,
        role: selectedRole,
        staringDate: startingDate,
        name: name,
        more: moreInfo,
      },
    ]);


    // Reset selectedRole after adding the user
    setSelectedRole('');
  };
  const promptForKeyValuePairs = () => {
    const moreInfo = {};
    let continueAdding = true;

    while (continueAdding) {
      const key = prompt('Enter a key:');
      const value = prompt('Enter a value:');

      moreInfo[key] = value;

      continueAdding = window.confirm('Do you want to add another key-value pair?');
    }

    return moreInfo;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const isFormValid = Object.values(formData).every(value => value !== '');

    // if (!isFormValid) {
    //     alert('Fill up all details before submitting.');
    //     return;
    // }
    // Add logic to handle form submission
    // console.log('Form submitted:', teamMembers);
    const postData = {

      teamMembers: teamMembers
    }
    // console.log("postData", postData)
    try {
      loadingToast = toast.loading("Adding New Members...");
      const postDatas = async () => {
        const res = await axios.patch(`${BACKEND_URL}/projects/add_members?projectId=${projectLIst[index]._id}`, postData ,{headers: headers} )
        .then((res)=>{
          toast.success(res.data.message, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
          setTimeout(()=>{
            setAddMember(false);
          },3000)
          setRefresh(refresh+1)
        })
        .catch((err)=>{
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            style: {
              marginTop: "62px",
            },
          });
        })
          setTimeout(()=>{
            setAddMember(false);
          },3000)
      }
      postDatas();
    } catch (err) {
      alert("Something went wrong, Try logging in again");
      errorHandleLogout();
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <div className="cover" style={{ color: 'black' }}>

      <div className="addProject">
        <div className="left" style={{ backgroundColor: "white" }}>
          <img src={cross} onClick={() => setAddMember(false)} />
          <h2>Do you Want to add new members</h2>
          <form >
            <h3>Previous Members</h3>
            {
              projectLIst[index].teamMembers.map((teamMember, tmIndex) => (
                <ul key={tmIndex}>
                  <li>
                    <strong>Role : </strong>
                    {teamMember.role}
                  </li>
                  <li>
                    <strong>Starting Date : </strong>
                    {teamMember.staringDate}
                  </li>
                  <li>
                    <strong>Name : </strong>
                    {teamMember.userId?.name}
                  </li>
                  <li>
                    <strong>EmpID : </strong>
                    {teamMember.userId?.empId}
                  </li>
                  <hr />
                </ul>
              ))
            }
            <div>
              <h2>Team Members:</h2>
              <br />
              <label>Select Role:</label>
              <input
                type="text"
                placeholder="Search roles..."
                value={searchTerm}
                onChange={handleSearchChange}
                onClick={(e) => e.stopPropagation()}
              />
              <select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select Role</option>
                {filteredRoles.map(role => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </select>
              <br />
              <label>
                Starting Date:
                <input
                  type="date"
                  name="startingDate"
                  value={startingDate}
                  onChange={(e) => setStartingDate(e.target.value)}
                />
              </label>
            </div>

            {/* Display the team members */}
            <div>

              <ul>
                {finalTeamMember.map((member, index) => (
                  <li key={index}>
                    Name:{member.name}, Role: {member.role}, More Info: {JSON.stringify(member.more)}
                  </li>
                ))}
              </ul>
            </div>


            <button style={{ cursor: 'pointer' }} onClick={handleSubmit} >Submit</button>
          </form>
        </div>
        <div className="right" style={{ backgroundColor: "white" }}>
          <table>
            <thead>
              <th>Sl no</th>
              <th>Emp Code</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Add</th>
            </thead>
            <tbody>
              {
                data.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.empId}</td>
                    <td>{item.name}</td>

                    <td>{item.designation}</td>
                    <td style={{ cursor: 'pointer' }}>
                      <button className="addUser" onClick={() => handleAddUser(item._id, item.name)}> ➕</button>

                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      
        <Toaster autoClose={2500}  />
      </div>
    </div>
  )
}
