import React from "react";

function Footer() {
    return (
        <>
            <div className="main_footer" style={{ paddingBottom: "10px" }}>
                <div className="first_container">
                    <h3>About Business </h3>
                    <p>
                        Crowned as IT consulting maestros, we possess the adeptness to dissect your
                        organizational dilemmas from a panoramic perspective. With finesse, we offer
                        tailored recommendations crafted to elevate your operational landscape.
                        Trust in our expertise to navigate the intricate pathways of your
                        challenges, paving the way for transformative solutions
                    </p>
                    <div className="footer_icons">
                        <a
                            href="https://www.facebook.com/dayaconsultancyservicespvt.ltd/"
                            target="blanck"
                        >
                            <img
                                width="24"
                                height="24"
                                src="https://img.icons8.com/material-outlined/24/FFFFFF/facebook-f.png"
                                alt="facebook-f"
                            />
                        </a>
                        <a
                            href="https://twitter.com/i/flow/login?redirect_after_login=%2FDayaservices"
                            target="blanck"
                        >
                            <img
                                width="24"
                                height="24"
                                src="https://img.icons8.com/material-rounded/24/FFFFFF/twitter.png"
                                alt="twitter"
                            />
                        </a>
                        <a href="https://www.instagram.com/dcs.odisha/" target="blanck">
                            <img
                                width="24"
                                height="24"
                                src="https://img.icons8.com/material-outlined/24/FFFFFF/instagram-new--v1.png"
                                alt="instagram-new--v1"
                            />
                        </a>
                        {/* <a href="https://www.linkedin.com/404/" target='blanck'>
            <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/FFFFFF/linkedin--v1.png" alt="linkedin--v1" />
            </a> */}
                    </div>
                </div>

                {/* <div className="second_container">
                    <h3>Quick Links</h3>
                    <ul className=" quick_links">
                        <ul>
                            <li>Make Appointment</li>
                            <li>customer services</li>
                            <li>Department services </li>
                            <li>About Company</li>
                        </ul>

                        <ul>
                            <li>Internship</li>
                            <li>Our Case Studies</li>
                            <li>Free Consultation</li>
                            <li>Meet Our experts</li>
                        </ul>
                    </ul>
                </div> */}
                <div
                    className="third_container"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "40%",
                    }}
                >
                    <h3>Contact Us</h3>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ width: "50%", fontSize: "0.8rem" }}>
                            <p>
                                <b>Our Adress :- </b>
                                <br />
                                <div style={{ paddingLeft: "30px" }}>
                                    <b>Head Ofc :</b> Puri, Odisha
                                    <br />
                                    <b>Branch Ofc : </b>B-19, 1<sup>st</sup> floor,
                                    <br /> Bhubaneswar Odisha{" "}
                                </div>
                            </p>
                            <p>
                                <b>Talk To Expert :- </b>
                                <div style={{ paddingLeft: "30px" }}>
                                    <a
                                        href="tel:+91 8144802704"
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        +91 8144802704
                                    </a>
                                </div>
                            </p>
                            <p>
                                <b>Email Us :- </b>
                                <div style={{ paddingLeft: "30px" }}>
                                    <a
                                        href="mailto:info@Dayacs.com"
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        info@Dayacs.com
                                    </a>
                                </div>
                            </p>
                        </div>
                        <iframe
                            className="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7484.503512911215!2d85.8454036!3d20.289843900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a14fd5712c61%3A0x9b02a71f624c28dd!2sDaya%20Consultancy%20Services%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1689834516745!5m2!1sen!2sin"
                            width={150}
                            height={120}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
