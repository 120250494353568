import './App.css';
import { AllRoutes } from './routes/AllRoutes';
import { AuthContextProvider } from './context/AuthContext';


function App() {
  return (
    <div className="App">
   <AuthContextProvider>
   <AllRoutes/>
   </AuthContextProvider>
{/* <DemoSearchPractice/> */}
    </div>
  );
}

export default App;
