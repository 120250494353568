import React, { useContext, useState } from "react";
import cross from "../../../assets/black cross.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AuthContext from "../../../context/AuthContext";


const roles = [
  "HR Specialist", "HR Manager", "HR Business Partner", "HR Executive", "HR Generalist",
  "HR Recruiter", "Sr HR Recruiter", "Account Manager", "Business Development Manager",
  "Business Partner", "Account Executive", "Project Manager", "Developer", "Sr Developer",
  "Software Developer", "Front End Developer", "Java Developer", "Software Architect",
  "Full Stack Developer", "Web Developer", "Software Engineer", "Freelancer",
  "Internship HR", "Internship IT"
];


export const UpdateTeamMember = ({
  index,
  projectLIst,
  setShowEditMember,
  setRefresh,
  refresh,
  tmIndex,
}) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  const { errorHandleLogout } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  const [Data, setData] = useState({
    role: "",
    staringDate: "",
    description: {},
  });
  let loadingToast; // Declare the variable outside the try block

  const handleDescriptionChange = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      description: {
        ...prevData.description,
        [key]: value,
      },
    }));
  };
  const handleAddDescription = () => {
    if (Data.tempKey && Data.tempValue) {
      setData((prevData) => ({
        ...prevData,
        description: {
          ...prevData.description,
          [Data.tempKey]: Data.tempValue,
        },
        tempKey: "",
        tempValue: "",
      }));
    }
  };
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredRoles = roles.filter(role =>
    role.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSubmit = async(e) => {
    e.preventDefault();
    // console.log("Data", Data);

    try {
      const postData = {
        role: Data.role,
        staringDate: Data.staringDate,
        more: Data.description,
      };
        await axios
          .patch(
            `${BACKEND_URL}/projects/update_member?projectId=${projectLIst[index]._id}&teamMemberId=${projectLIst[index].teamMembers[tmIndex]._id}`,
            postData ,{headers: headers}
          )
          .then((res) => {
            // console.log("res.data", res.data.message);
            // toast.success(`${res.data.message}`);
            toast.success(res.data.message, {
              position: "top-right",
              style: {
                marginTop: "62px",
              },
            });
            setRefresh(refresh + 1);
            setShowEditMember(false);
          })
          .catch((err)=>{
            toast.error(`${err.response.data.message}`, {
              position: "top-right",
              style: {
                marginTop: "62px",
              },
            });
            // setShowEditMember(false)
          })
    
    } catch (err) {
      alert("Something went wrong, Try logging in again");
      errorHandleLogout();
    }
  };
  // console.log("projectLIst[index]", projectLIst[index]);
  // console.log(
  //   "projectLIst[index]",
  //   projectLIst[index].teamMembers[tmIndex]._id
  // );
  return (
    <div className="cover" style={{ color: "black" }}>
      <div className="left" style={{ backgroundColor: "white" }}>
        <img src={cross} onClick={() => setShowEditMember(false)} />
        <h2>Do you Want to update details of Member</h2>
        <form onSubmit={handleSubmit}>
          <h3>Previous Details</h3>
          <ul key={tmIndex}>
            <li>
              <strong>Role : </strong>
              {projectLIst[index].teamMembers[tmIndex].role}
            </li>
            <li>
              <strong>Starting Date : </strong>
              {projectLIst[index].teamMembers[tmIndex].staringDate}
            </li>
            <li>
              <strong>Name : </strong>
              {projectLIst[index].teamMembers[tmIndex].userId?.name}
            </li>
            <li>
              <strong>EmpID : </strong>
              {projectLIst[index].teamMembers[tmIndex].userId?.empId}
            </li>
            <hr />
          </ul>
          <h3>Update Details</h3>
          <div>
            <label>Role</label>
            <input
                type="text"
                placeholder="Search roles..."
                value={searchTerm}
                onChange={handleSearchChange}
                onClick={(e) => e.stopPropagation()}
              />
            <select
              onChange={(e) => setData({ ...Data, role: e.target.value })}
            >
              <option value="">Select Role</option>
                {filteredRoles.map(role => (
                  <option key={role} value={role}>{role}</option>
                ))}
            </select>
            <label>staring Date</label>
            <input
              type="date"
              name="staringDate"
              value={Data.staringDate}
              onChange={(e) =>
                setData({ ...Data, staringDate: e.target.value })
              }
            />
            <label>More:</label>

            {Object.entries(Data.description).map(([key, value], index) => (
              <div id="description" key={index}>
                {key}:
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleDescriptionChange(key, e.target.value)}
                />
              </div>
            ))}

            <div>
              <input
                type="text"
                placeholder="Key"
                value={Data.tempKey}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    tempKey: e.target.value,
                  }))
                }
              />
              :
              <input
                type="text"
                placeholder="Value"
                value={Data.tempValue}
                onChange={(e) =>
                  setData((prevData) => ({
                    ...prevData,
                    tempValue: e.target.value,
                  }))
                }
              />
              <button type="button" onClick={handleAddDescription}>
                Add
              </button>
            </div>
          </div>

          <button style={{ cursor: "pointer" }} type="Submit">
            Submit
          </button>
        </form>
      </div>
      {/* <ToastContainer /> */}
      <Toaster />
    </div>
  );
};
