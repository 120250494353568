import React, { useContext, useEffect, useState } from "react";
import loginLogo from "../assets/loginLogo.avif";
import "../allCss/login.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dayalogo from "../assets/dayalogo.png";
import wback from "../assets/WBack.jpeg";
import axios from "axios";
import profilepage1 from "../assets/profilepage1.avif";
import AuthContext from "../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  // const [token, SetToken] = useState(null);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(()=>{
    if(token && role){
      if(role === "admin"){
        navigate("/admin/home")
      }else{
        navigate("/home")
      }  
    }
  },[])

  useEffect(()=>{

  },[])

  const handelSubmit = (e) => {
    e.preventDefault();
    // console.log(data);
    if (data) {
      setUser({
        email: data.email,
        password: data.password,
      });
    }
  };
  return (
    <div className="login_page">
      <div className="left_page">
        <div>
          <img
            style={{ height: "10vh", width: "30%" }}
            src={wback}
            alt="logo-img"
          ></img>
          <img src={profilepage1} alt="profilepage1"></img>
        </div>
      </div>
      <div className="right_page">
        <form className="login_form" onSubmit={handelSubmit}>
          <label htmlFor="email"> UserID :</label>
          <input
            type="text"
            name="email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <label htmlFor="password">Password :</label>
          <input
            type="password"
            name="password"
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
