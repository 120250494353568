import React, { useContext, useEffect, useState } from "react";
import dayalogo from "../assets/dayalogo.png";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function Header() {
  const [toggle, setToggle] = useState(false);
  const [time, setTime] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [arrow, setarrow] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const { handelSignOut } = useContext(AuthContext);
  const dropDownMenu = () => {
    setToggle(!toggle);
  };

  const handelClick = () => {
    setarrow(!arrow);
    setDropDown(!dropDown);
  };

  useEffect(() => {
    setInterval(() => setTime(new Date()), 1000);
    setInterval(() => setDate(new Date()));
  }, []);
  return (
    <>
      <header className="main-header">
        <div className="h_dt_tm">
          <p onClick={dropDownMenu}>
            <img
              width="30"
              height="30"
              src="https://img.icons8.com/ios-filled/50/FFFFFF/squared-menu.png"
              alt="squared-menu"
            />
            Menu
          </p>

          <div className="left_header">
            <a href="https://www.dayacs.com/" target="blanck">
              <img width="200" height="80" src={dayalogo} alt="logo-img"></img>
            </a>
          </div>
          <div className="dt_time">
            <p>{time.toLocaleTimeString()}</p>

            <p>{date.toLocaleDateString()}</p>
          </div>
        </div>

        <div className="right_header">
          <NavLink to="/admin/home">
            <p> Home</p>
          </NavLink>
          {/* <p> About</p>
                    <div onClick={handelClick} className="dropdown">
                        <div className="dropdown_title">
                            <p>Services </p>
                            {arrow ? (
                                <img
                                    width="24"
                                    height="24"
                                    src="https://img.icons8.com/material/24/FFFFFF/collapse-arrow--v1.png"
                                    alt="collapse-arrow--v1"
                                />
                            ) : (
                                <img
                                    width="24"
                                    height="24"
                                    src="https://img.icons8.com/material-outlined/24/FFFFFF/expand-arrow--v1.png"
                                    alt="expand-arrow--v1"
                                />
                            )}
                        </div>
                    </div>
                    <p> Contact</p> */}
          <p onClick={handelSignOut}> Sign Out</p>
        </div>
      </header>
      <div className={`dropdown_content ${arrow ? "" : "hidden"}`}>
        <p>Hii</p>

        <p>Hii</p>
        <p>Hii</p>
      </div>
      <div className="sidebar">
        {toggle ? (
          <div className="sidebar-menu">
            <NavLink to="/admin/home">
              {" "}
              <p onClick={() => setToggle(false)}>Employee List</p>
            </NavLink>
            <NavLink to="/admin/register">
              {" "}
              <p onClick={() => setToggle(false)}>Register New Employee</p>
            </NavLink>
            <NavLink to="/admin/leave_appliacation">
              {" "}
              <p
                onClick={() => {
                  localStorage.removeItem("adminSetUser");
                  setToggle(false);
                }}
              >
                Leave Applications
              </p>
            </NavLink>
            <NavLink to="/holiday-list">
              {" "}
              <p onClick={() => setToggle(false)}>Holiday List</p>
            </NavLink>
            <NavLink to="/admin/add_holiday">
              {" "}
              <p onClick={() => setToggle(false)}>Add Holiday</p>
            </NavLink>
            <NavLink to="/admin/project_list">
              {" "}
              <p onClick={() => setToggle(false)}>Project List</p>
            </NavLink>
            <NavLink to="/admin/add_project">
              {" "}
              <p onClick={() => setToggle(false)}>Add Project</p>
            </NavLink>
            <NavLink to="/admin/deleted_emps">
              {" "}
              <p onClick={() => setToggle(false)}>Deleted Employees</p>
            </NavLink>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Header;
