import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/AuthContext';
import toast, { Toaster } from 'react-hot-toast';
import { dustbin, tick } from '../../assets';
import { values } from 'lodash';
export const DeletedEmployee = () => {
    const [datas, setDatas] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const { errorHandleLogout } = useContext(AuthContext);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `Bearer ${token}`,
    }
    let loadingToast;
    useEffect(() => {
        try {
            const fetchData = async () => {
                await axios.get(`${BACKEND_URL}/users/get_deleted_user`, { headers: headers })
                    .then(res => {
                        // console.log(res);
                        setDatas(res.data.users);
                        toast.success(res.data.message, {
                            position: 'top-right',
                            style: {
                              marginTop: '62px',
                            }
                          })
              
                    })
                    .catch(err => {
                        // console.log(err)
                        toast.error(`${err.response.data.message}`, {
                            position: 'top-right',
                            style: {
                              marginTop: '62px',
                            }
                          });
                    })
            }
            fetchData();

        } catch (err) {
            alert("Something went wrong, Try logging in again");

            errorHandleLogout();
        } finally {
            // Close the loading state
            if (loadingToast) {
                toast.dismiss(loadingToast);
            }
        }
        
    }, [refresh])
    const handelRetrive = async(value)=>{
// console.log (value)
// console.log(datas[value]._id)
const conFirmRetrive = window.confirm("Are you sure you want to Retrive ");
if(conFirmRetrive){
    try{
        loadingToast = toast.loading("Retrive empolyess...", {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          });
await axios.get(`${BACKEND_URL}/users/retrive_user?userId=${datas[value]._id}`,{headers: headers})
.then((res)=>{
    toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      })
      setRefresh(refresh + 1)
}).catch((err)=>{
    toast.error(`${err.response.data.message}`, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      })
})
    }catch{
        alert("Something went wrong, Try logging in again");
        errorHandleLogout();
    }finally {
        if (loadingToast) {
          toast.dismiss(loadingToast, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          });
        }
      }
}
    }
    const handelDelete = async (value)=>{
        // console.log(datas[value]._id)
        const conFirmDelete = window.confirm("Are you sure you want to Delete permanently ");
        if(conFirmDelete){
            try{
                loadingToast = toast.loading("Permanently Deleting empolyess...", {
                    position: 'top-right',
                    style: {
                      marginTop: '62px',
                    }
                  });
            await axios.delete(`${BACKEND_URL}/users/perm_delete_user?userId=${datas[value]._id}`,{headers: headers})
            .then((res)=>{
                toast.success(res.data.message, {
                    position: 'top-right',
                    style: {
                      marginTop: '62px',
                    }
                  })
                  setRefresh(refresh + 1)
            }).catch((err)=>{
                toast.error(`${err.response.data.message}`, {
                    position: 'top-right',
                    style: {
                      marginTop: '62px',
                    }
                  })
            })
            }catch{
                alert("Something went wrong, Try logging in again");
                errorHandleLogout();
            }finally {
                if (loadingToast) {
                  toast.dismiss(loadingToast, {
                    position: 'top-right',
                    style: {
                      marginTop: '62px',
                    }
                  });
                }
              }
        }
    }
    return (
        <div className="project_list">

            <h1>Projects:</h1>
            <br />
            <label htmlFor="searchBar">Search Project Name or ID: </label>
            {/* <input
      type="text"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search..."
    /> */}
            <br /><br />
            <table>
                <thead>
                    <th>Sl.No</th>
                    <th>Emp ID</th>
                    <th>Emp Name</th>
                    <th>Emp Desig.</th>
                    <th>No. of Projects Involved</th>
                    <th>Retrive Emp</th>
                    <th>Delete Emp</th>

                </thead>
                <tbody>
                    {
                        datas.map((data, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.empId}</td>
                                <td>{data.name ? data.name : NaN}</td>
                                <td>{data.designation}</td>
                                <td>{data.projects.length}</td>
                                <td>
                                    <img src={tick} width={20} height={20} style={{ cursor: 'pointer' }} alt="tick" 
                                        onClick={()=>handelRetrive(index)}
                                    />
                                </td>
                                <td>
                                    <img src={dustbin} width={20} height={20} style={{ cursor: 'pointer' }} alt="dustbin" 
                                        onClick={()=>handelDelete(index)}
                                    />
                                </td>

                            </tr>
                        ))
                    }

                </tbody>
            </table>

            {/* <ToastContainer /> */}
            <Toaster />
        </div>
    )
}
