import React, { useState, useEffect } from "react";
import "../../allCss/admincss/addHoliday.css";
import deelte from "../../assets/delete.png";
import plus from "../../assets/plus.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const AddHolidayForm = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  let loadingToast;
  if (role !== "admin") {
    navigate("/home");
  }

  const [holidays, setHolidays] = useState([{ name: "", dte: "" }]);
  const handelPlus = () => {
    setHolidays([...holidays, { name: "", dte: "" }]);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    console.log("all holidays", holidays);

    try {
      
      await axios
        .post(
          `${BACKEND_URL}/holiday/add_holiday`,
          { holidayList: holidays },
          { headers: headers }
        )
        .then((res) => {              
          if (res.data.success === true) {
            // console.log("api all holiday", res.data);
            // alert(`${res.data.message}`);
            console.log(`${res.data.message}`);
            toast.success(`${res.data.message}`);
            
          } else {
            // console.log("api all holiday", res);
            // alert(`${res.data.message}`);
            console.log(`${res.data.message}`);
            toast.error(`${res.data.message}`);
          }
        })
        .catch((err) => {
          console.log("404 error showing:", err.response.data.message);
          toast.error(err.response.data.message);
        });
      
    } catch (err) {
      if (err.response) {
        console("404 error showing1:", err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
      // alert(err.res.data.message)
    } finally {
      // This block will execute whether the request succeeds or fails
      console.log("Request completed");
    }
  };
  const handelInputChange = (index, key, value) => {
    const updatedHoliday = [...holidays];
    updatedHoliday[index][key] = value;
    setHolidays(updatedHoliday);
  };
  const handelDelete = (index) => {
    const updatedHolidays = holidays.filter((holiday, i) => i !== index);
    setHolidays(updatedHolidays);
  };

  return (
    <div className="add-holiday">
      <p>Add Holidays</p>
      <form onSubmit={handelSubmit}>
        <div className="addholoday-plus">
          {holidays.map((holiday, index) => (
            <div key={index} className="addhiliday-img">
              <div className="addHolidayform">
                <input
                  type="text"
                  placeholder="enter holidays Name"
                  value={holiday.name}
                  onChange={(e) =>
                    handelInputChange(index, "name", e.target.value)
                  }
                />
                <input
                  type="date"
                  value={holiday.dte}
                  onChange={(e) =>
                    handelInputChange(index, "dte", e.target.value)
                  }
                />
                <img
                  src={deelte}
                  alt="delete"
                  onClick={() => handelDelete(index)}
                />
              </div>
            </div>
          ))}
          <img src={plus} alt="add" onClick={handelPlus} />
        </div>
        <button type="submit">Submit</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddHolidayForm;
