import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "../../allCss/usercss/holidaytimesheet.css"
import AuthContext from "../../context/AuthContext";

const Holiday = ({ setShowFrom, startingDate, endingDate }) => {
  const {errorHandleLogout}=useContext(AuthContext)
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [holidays, setHolidays] = useState([]);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  useEffect(() => {

    try {
      axios.get(`${BACKEND_URL}/holiday/get_holidays?startDate=${startingDate}&endDate=${endingDate}`, { headers: headers })
        .then((res) => {
          console.log("holiday leave list :", res.data.data);
          // console.log("holiday leave list :",res.data.data);
          setHolidays(res.data.data);
        })
        .catch((err) => {
          console.log('err', err);
          alert("Error fetching holidays")
        })

    } catch (err) {
      console.log("err", err);
      alert("Something went wrong, Try logging in again");
      errorHandleLogout();

    }
  }, [startingDate, endingDate])

  // useEffect(()=>{
  //   console.log("holidays:",holidays)
  // },[holidays])
  const handelHide = () => {
    setShowFrom();
  };
  return (
    <div className="holiday-timesheet">
      <div className="holiday-day">
        <img
          width="24"
          height="24"
          onClick={handelHide}
          src="https://img.icons8.com/material-outlined/24/FFFFFF/multiply--v1.png"
          alt="multiply--v1"
        />


        {holidays.map((item, index) => (
          <>
            <p key={index}>{new Date(item.dte).toISOString().split('T')[0]} : {item.name} </p>
          </>
        ))
        }
      </div>
    </div>
  );
};

export default Holiday;
