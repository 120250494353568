import React, { useContext, useEffect, useState } from "react";
import dayalogo from "../assets/dayalogo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import dprofile from "../assets/dp.png";

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [toggle, setToggle] = useState(false);
    const [time, setTime] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const [arrow, setarrow] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [signOut, setSignOut] = useState(false);
    const [profile, setProfile] = useState(false);
    const { handelSignOut } = useContext(AuthContext);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const image = localStorage.getItem("dp");
    useEffect(() => {
        if (location) {
            console.log(location.pathname);
        }
    }, [location]);

    const dropDownMenu = () => {
        setToggle(!toggle);
    };
    const handelprofile = () => {
        setProfile(!profile);
    };

    const handelClick = () => {
        setarrow(!arrow);
        setDropDown(!dropDown);
    };

    useEffect(() => {
        setInterval(() => setTime(new Date()), 1000);
        setInterval(() => setDate(new Date()));
    }, []);
    return (
        <>
            <header className="main-header">
                <div className="h_dt_tm">
                    <p onClick={dropDownMenu}>
                        <img
                            width="30"
                            height="30"
                            src="https://img.icons8.com/ios-filled/50/FFFFFF/squared-menu.png"
                            alt="squared-menu"
                        />
                    </p>

                    <div className="left_header">
                        <a href="https://www.dayacs.com/" target="blanck">
                            <img width="200" height="80" src={dayalogo} alt="logo-img"></img>
                        </a>
                    </div>
                    <div className="dt_time">
                        <p>{time.toLocaleTimeString()}</p>

                        <p>{date.toLocaleDateString()}</p>
                    </div>
                </div>

                <div className="right_header">
                    {location.pathname != "/home" && (
                        <NavLink to="/home">
                            <p> Home</p>
                        </NavLink>
                    )}

                    {/* <p style={{ display: "none" }}> About</p> */}
                    {/* <div onClick={handelClick} className="dropdown" style={{ display: "none" }}>
                        <div className="dropdown_title">
                            <p>Services </p>
                            {arrow ? (
                                <img
                                    width="24"
                                    height="24"
                                    src="https://img.icons8.com/material/24/FFFFFF/collapse-arrow--v1.png"
                                    alt="collapse-arrow--v1"
                                />
                            ) : (
                                <img
                                    width="24"
                                    height="24"
                                    src="https://img.icons8.com/material-outlined/24/FFFFFF/expand-arrow--v1.png"
                                    alt="expand-arrow--v1"
                                />
                            )}
                        </div>
                    </div> */}
                    <NavLink to="/CEOContent">
                        <p> CEO Content</p>
                    </NavLink>
                    {image == "undefined" ? (
                        <img
                            src={dprofile}
                            alt="dp"
                            style={{
                                height: "6vh",
                                width: "38px",
                                borderRadius: "20px",
                                margin: "0 10px",
                                background: "white",
                            }}
                            onClick={handelprofile}
                        />
                    ) : (
                        <img
                            src={`${BACKEND_URL}${image}`}
                            alt="dp"
                            style={{
                                height: "6vh",
                                width: "38px",
                                borderRadius: "20px",
                                margin: "0 10px",
                                background: "white",
                            }}
                            onClick={handelprofile}
                        />
                    )}
                </div>
            </header>
            <div className={`profile_dp ${profile ? "" : "hidden"}`}>
                <p>Hi {localStorage.getItem("name")}</p>
                <p onClick={handelSignOut} style={{ cursor: "pointer" }}>
                    {" "}
                    Sign Out
                </p>
            </div>
            <div className={`dropdown_content ${arrow ? "" : "hidden"}`}>
                <p>Hii</p>
                <p>Hii</p>
                <p>Hii</p>
            </div>
            <div className="sidebar">
                {toggle ? (
                    <div className="sidebar-menu">
                        <NavLink to="/timesheet">
                            <p onClick={() => setToggle(false)}>Timesheet</p>
                        </NavLink>
                        <NavLink to="/apply_leave">
                            <p onClick={() => setToggle(false)}>Apply Leave</p>
                        </NavLink>
                        <NavLink to="/update_user">
                            <p onClick={() => setToggle(false)}>Update User</p>
                        </NavLink>
                        <NavLink to="/holiday-list">
                            <p onClick={() => setToggle(false)}>Holiday List</p>
                        </NavLink>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}

export default Header;
